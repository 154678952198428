<template>
  <c-box h="100%" py="8" :px="{ base: '6', lg: 0 }">
    <c-flex align="center" justify="space-between" mb="8">
      <c-flex align="baseline">
        <c-heading fontSize="3xl" fontWeight="700" mr="3">
          View Post
        </c-heading>
      </c-flex>
      <Notification />
    </c-flex>
    <c-box>
      <c-text v-if="!isFetchingPost && !posts.length" fontStyle="italic">
        You have no posts on your timeline
      </c-text>
      <template v-for="post in posts">
        <c-box :key="post.id">
          <NewsCard
            @togglePostSave="onPostSaveToggle"
            @selectPostAttachment="
              selectedPost = $event;
              isImagePreviewOpen = true;
            "
            @deletePost="onDeletePost(post)"
            :post="post"
          />
        </c-box>
      </template>
    </c-box>
  </c-box>
</template>

<script>
import Notification from '../components/Notification.vue';
import NewsCard from './components/NewsCard.vue';
import { mapState } from 'vuex';
import {
  getPost,
  addPostSave,
  deletePostSave,
} from '../../../services/insight';
import { generateID } from '../../../helpers/data';
import cloneDeep from 'lodash.clonedeep';

export default {
  components: {
    Notification,
    NewsCard,
  },
  data() {
    return {
      isFetchingPost: true,
      posts: [],
    };
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
  },
  created() {
    this.getPost();
  },
  methods: {
    getPost() {
      const postId = this.$route.params.id;
      this.isFetchingPost = true;
      getPost({
        id: postId,
        userId: this.user.id,
      }).then((res) => {
        console.log('view post', res.data);
        this.posts = res.data.post;
        this.isFetchingPost = false;
      });
    },
    async onPostSaveToggle(post) {
      const data = {
        postId: post.id,
        userId: this.user.id,
      };
      const postIndex = this.posts.findIndex((_post) => post.id === _post.id);
      if (post.post_saves.length) {
        const savedPostIndex = this.savedPosts.findIndex(
          (savedPost) => savedPost.post.id === post.id
        );
        const savedPost = cloneDeep(this.savedPosts[savedPostIndex]);
        this.posts[postIndex].post_saves.pop();
        this.savedPosts.splice(savedPostIndex, 1);
        try {
          await deletePostSave(savedPost.id);
        } catch (e) {
          this.savedPosts.splice(savedPostIndex, 0, { ...savedPost });
          this.posts[postIndex].post_saves.push({ ...data });
          this.$toast({
            title: 'An error occurred.',
            description: `Couldn't remove saved post, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      } else {
        this.posts[postIndex].post_saves.push({ ...data });
        const genId = generateID(4);
        this.savedPosts.push({ id: genId, post: { ...post } });
        const savedPostIndex = this.savedPosts.findIndex(
          (savedPost) => savedPost.id === genId
        );
        try {
          const res = await addPostSave(data);
          const { id } = res.data.insert_post_save_one;
          this.savedPosts[savedPostIndex].id = id;
        } catch (e) {
          const savedPostIndex = this.savedPosts.findIndex(
            (savedPost) => savedPost.post.id === post.id
          );
          this.savedPosts.splice(savedPostIndex, 1);
          this.posts[postIndex].post_saves.pop();
          this.$toast({
            title: 'An error occurred.',
            description: `Couldn't save post, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      }
    },
  },
};
</script>
